import React from 'react';
import { DefaultView } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Container, Grid, Divider, Card, Header, Image, Label, List, Segment } from 'semantic-ui-react';

import moment from "moment";

import './Project.css';

const ProjectView = (props) => {
  const { content } = props;

  return (
    <Container id="view-wrapper" className="ProjectView">
      <div>

      {content.title && (
          <>
            <h2> {content.title}</h2>
          </>
        )}

        {/* Conditional rendering for the abstract and image */}
        <div className="abstract-and-image">

          {content.project_abstract && content.project_abstract?.data !="<p></p>" && (
            <div className="abstract-text">
              <div dangerouslySetInnerHTML={{ __html: content.project_abstract.data }} />
            </div>
          )}
        </div>

        <div className="images-container">
          {content.project_teaser_image1 && (
            <img
              src={flattenToAppURL(content.project_teaser_image1.scales.preview.download)}
              alt="Project"
              className="project-image"
            />
          )}
          {content.project_teaser_image2 && (
            <img
              src={flattenToAppURL(content.project_teaser_image2.scales.preview.download)}
              alt="Project"
              className="project-image"
            />
          )}
        </div>

        {content.project_website && (
          <>
            <a className={'clickable-box'} href={content.project_website}>
              Project Website
            </a>
          </>
        )}

        {content.project_start_date && (
          <>
            <h3> Project start date:</h3>
            {content.project_start_date}
          </>
        )}
        {content.project_end_date && (
          <>
            <h3> Project end date:</h3>
            {content.project_end_date}
          </>
        )}

        {content.project_funding && (
          <>
            <h3> Funding: </h3>
            {content.project_funding}
          </>
        )}


      </div>
      <br/>
    <DefaultView {...props} />
  </Container>
  );
};
export default ProjectView;

