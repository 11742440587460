import React from 'react';
import { DefaultView } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink, ConditionalLink, Link } from '@plone/volto/components';
import { Container, Grid, Divider, Card, Header, Image, Label, List, Segment } from 'semantic-ui-react';

import moment from "moment";

import './Course.css';

const CourseView = (props) => {
  const { content } = props;

  return (
    <Container id="view-wrapper" className="CourseView">
      <Grid>
        <Grid.Row>

           <Grid.Column>

           {content.course_academic_level && (
              <>
                <h2>
                  {content.course_academic_level.title} <br></br>
                </h2>
              </>
            )}

            {content.type_of_course && (
              <>
                <h2>
                  {content.type_of_course.title} {' '} {content.title ? content.title : content.id} <br></br>
                </h2>
              </>
            )}


             {content.course_description && content.course_description?.data !="<p></p>" && (
                  <>
                    <p dangerouslySetInnerHTML={{ __html: content.course_description.data }} />
                  </>
                )}

              <hr></hr>

              {content.course_semester  && (
              <>
              <h3>Semester:</h3>
                {content.course_semester && content.course_semester.join(', ')}
              </>
            )}

            {content.course_year && (
              <>
                <h3>Year:</h3>
                {content.course_year && content.course_year.join(', ')}
              </>
            )}

            {content.course_number && (
              <>
              <h3>Course Number:</h3>
                {content.course_number}
              </>
            )}

             {
               (content.course_basis_link ||
               content.course_ecampus_link) &&
               <h3>Links:</h3>
             }

            {content.course_basis_link &&
              <>
                <a href={content.course_basis_link} className={"clickable-box"}>Basis</a>
              </>
            }

            {content.course_ecampus_link &&
              <>
                <a href={content.course_ecampus_link} className={"clickable-box"}>E-Campus</a>
              </>
            }

            {content.course_start_date && (
              <>
                <h3> Course Start Date:</h3>
                {content.course_start_date}

              </>
            )}

            {content.course_end_date && (
              <>
                <h3> Course End Date: </h3>
                {content.course_end_date}

              </>
            )}

            {content.course_dates && (
              <>
                <h3> Course Dates: </h3> {content.course_dates}

              </>
            )}

            {content.course_ects && (
                <>
                  <h3> ECTS: </h3>
                  {content.course_ects}
                </>
              )}


            {content.course_related_lecturer && (
              <>
                <br></br>
                <h3> Responsible HRL Lecturers: </h3>
                <Card.Group>
                  {content.course_related_lecturer.map((item) => (

                    <Card fluid color='grey' className="PeopleShortCard" key={item['@id']}>
                      <Card.Content>
                        <UniversalLink href={item['@id']} title={item['@type']}>
                          <h3 className='PeopleName'>{ item.type_of_degree + ' ' + item.full_name}</h3>
                        </UniversalLink>
                      </Card.Content>
                    </Card>
                  ))}
                </Card.Group>
                <br></br>
              </>
            )}

           </Grid.Column>

        </Grid.Row>

      </Grid>
    <DefaultView {...props} />
  </Container>
  );
};

export default CourseView;

