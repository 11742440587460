import React, { useEffect, useState } from 'react';
import { DefaultView } from '@plone/volto/components';
import { UniversalLink, ConditionalLink, Link } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { keyBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Container, Grid, Divider, Card, Header, Image, Label, List, Segment } from 'semantic-ui-react';
import { api } from '@plone/volto';

import moment from "moment";

import './Topic.css';
import './Project.css';


const TopicView = (props) => {
  const { content } = props;

  return (

    <Container id="view-wrapper" className="TopicView">

          <div>

            {content.title && (
              <>
                <h2> {content.title} </h2>

              </>
            )}

            {content.topic_description && content.topic_description?.data !="<p></p>" && (
              <>
                  <div dangerouslySetInnerHTML={{ __html: content.topic_description.data }} />
              </>
            )}

            <div className="images-container">
              {content.topic_teaser_image1 && (
                <img
                  src={flattenToAppURL(content.topic_teaser_image1.scales.preview.download)}
                  alt="Topic"
                  className="project-image"
                />
              )}
              {content.topic_teaser_image2 && (
                <img
                  src={flattenToAppURL(content.topic_teaser_image2.scales.preview.download)}
                  alt="Topic"
                  className="project-image"
                />
              )}
            </div>

          </div>
      <br/>

      <DefaultView {...props} />

    </Container>

  );
};
export default TopicView;


