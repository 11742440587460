import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import { Card, Container } from 'semantic-ui-react';

const titleListingTemplate = ({ items }) => {
  return (
    <>
      <Container className="view-wrapper">
        <Card.Group>
          {items &&
            items.map((item) => (
              <Card fluid color="grey" className="Card" key={item['@id']}>
                <Card.Content>
                  <UniversalLink href={item['@id']} title={item['@type']}>
                    <h3>{item.title ? item.title : item.id}</h3>
                  </UniversalLink>
                </Card.Content>
              </Card>
            ))}
        </Card.Group>
      </Container>
    </>
  );
};

export default titleListingTemplate;
