import React from 'react';
import { Card, Image, Container } from 'semantic-ui-react';
import moment from 'moment';
import { useFetchItemDetails } from '../../../../components'; // Stellen Sie sicher, dass der Pfad korrekt ist

// Einzelnes News-Item, das den Hook zur Datenabfrage verwendet
const NewsItem = ({ relativePath }) => {
  const { itemDetails, isLoading, error } = useFetchItemDetails(relativePath);

  // Ladezustand
  if (isLoading) return <p>Lädt...</p>;
  // Fehlerzustand
  if (error) return <p>Fehler beim Laden der Details: {error.message}</p>;
  // Keine Daten vorhanden
  if (!itemDetails) return null;

  // Darstellung der News-Item-Daten
  return (
    <Card color="grey" className="Card NewsCard">
      <Image
        className="NewsImage"
        src={
          itemDetails.image?.scales?.preview?.download ||
          '/news_default_image.png'
        }
        floated="left"
        alt={itemDetails.title || 'Default Image Caption'}
      />
      <Card.Content className="NewsContent">
        <h4 className="NewsName">{itemDetails.title}</h4>
        <Card.Meta>
          <span className="NewsDate">
            {moment(itemDetails.effective).format('MMMM D, YYYY')}
          </span>
        </Card.Meta>
        <div dangerouslySetInnerHTML={{ __html: itemDetails.text?.data }} />
      </Card.Content>
    </Card>
  );
};

// Hauptkomponente, die eine Liste von News-Items anzeigt
const NewsListing2Template = ({ items }) => {
  return (
    <Container className="view-wrapper">
      <article>
        <section>
          <Card.Group>
            {items.map((item) => (
              // Verwenden Sie das Feld `url` aus Ihren Item-Daten als `relativePath`
              <NewsItem key={item['@id']} relativePath={item['@id']} />
            ))}
          </Card.Group>
        </section>
      </article>
    </Container>
  );
};

export default NewsListing2Template;
