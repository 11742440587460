import React from 'react';
import PropTypes from 'prop-types';
import { Card, Container, Segment, Image, List, Grid } from 'semantic-ui-react';
import {
  UniversalLink,
  ConditionalLink,
  Component,
} from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import moment from 'moment';

import './newsListingTemplate.css';

const newsListingTemplate = ({ items }) => {
  return (
    <>
      <Container className="view-wrapper">
        <article>
          <section>
            <Card.Group>
              {items &&
                items.map((item) => (
                  <Card
                    color="grey"
                    className="Card NewsCard"
                    key={item['@id']}
                  >
                    <Image
                      className="NewsImage"
                      src={
                        item.image_scales?.image?.[0]?.scales?.large
                          ? item['@id'] + '/' + flattenToAppURL(item.image_scales.image[0].scales.large.download)
                          : item.image_scales?.image?.[0]?.scales?.preview
                          ? item['@id'] + '/' + flattenToAppURL(item.image_scales.image[0].scales.preview.download)
                          : '/news_default_image.png'
                      }
                      floated="left"
                      alt={item.image_caption || 'Default Image Caption'}
                    />

                    <Card.Content className="NewsContent">
                      <h4 className="NewsName">
                        {item.title ? item.title : item.id}
                      </h4>

                      <Card.Meta>
                        <p className="NewsDate">
                          {moment(item.effective).format('MMMM D, YYYY')}
                        </p>
                      </Card.Meta>

                      {item.description && <p> {item.description} </p>}

                      {item.link_field && (
                        <>
                          {/* <h3>Video Link:</h3> */}
                          <a
                            href={item.link_field}
                            className={'clickable-box-listing'}
                          >
                            More info
                          </a>
                        </>
                      )}
                    </Card.Content>
                  </Card>
                ))}
            </Card.Group>
          </section>
        </article>
      </Container>
    </>
  );
};

newsListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default newsListingTemplate;
