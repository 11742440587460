import React from 'react';
import PropTypes from 'prop-types';
import { DefaultView } from '@plone/volto/components';
import {
  UniversalLink,
  ConditionalLink,
  Component,
} from '@plone/volto/components';
import { Card, Container, Segment, Image, List, Grid } from 'semantic-ui-react';

import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import moment from 'moment';

import './projectListingTemplate.css';

const projectListingTemplate = ({ items }) => {
  return (
    <>
      <Container className="view-wrapper">
        <Card.Group>
          {items &&
            items.map((item) => (
              <Card
                fluid
                color="grey"
                className="Card ProjectCard"
                key={item['@id']}
              >
                <Grid className="ProjectGrid" stackable columns={2}>
                  <Grid.Column className="ProjectImageColumn" width={7}>
                    <Segment className="SegmentInvisible">
                      {item.image_scales && item.image_scales.image && (
                        <UniversalLink href={item['@id']} title={item['@type']}>
                          <Image
                            className="ProjectImage"
                            src={
                              item.image_scales?.image?.[0]?.scales?.preview
                                ? item['@id'] +
                                  '/' +
                                  flattenToAppURL(
                                    item.image_scales.image[0].scales.preview
                                      .download,
                                  )
                                : item['@id'] +
                                  '/' +
                                  flattenToAppURL(
                                    item.image_scales.image[0].scales.mini
                                      .download,
                                  )
                            }
                            size="mini"
                            floated="left"
                            alt={item.image_caption}
                          />
                        </UniversalLink>
                      )}
                    </Segment>
                  </Grid.Column>
                  <Grid.Column className="ProjectContentColumn" width={9}>
                    <Segment className="SegmentInvisible">
                      <UniversalLink href={item['@id']} title={item['@type']}>
                        <h3>{item.title ? item.title : item.id}</h3>
                      </UniversalLink>

                      {item.description && (
                        <>
                          <p className={'block-description'}>
                            {item.description}
                          </p>
                        </>
                      )}
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Card>
            ))}
        </Card.Group>
      </Container>
    </>
  );
};

projectListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default projectListingTemplate;
