import React from 'react';
import { DefaultView } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  Container,
  Grid,
  Image,
  Segment,
  AccordionTitle,
  AccordionContent,
  Accordion,
  Icon,
} from 'semantic-ui-react';

import './Publication.css';

const PublicationView = (props) => {
  const { content } = props;

  return (
    <Container id="view-wrapper" className="PublicationView">
      <Grid stackable columns={2}>

        <Grid.Row>
            <Grid.Column>

            <h2 className='PublicationsTitle'>{content.title ? content.title : content.id}</h2>
            <br></br>


        <hr></hr>

              <br></br>


        <span className="PublicationsInfo">
        <p>
            <h3> Authors:</h3>
            {content.publication_authors && content.publication_authors.join(', ')}

        </p>
        <p>
            <h3>Type:</h3>
                {content.type_of_publication && content.type_of_publication.title}

        </p>
        <p>
            <h3>Published in: </h3>
          {content.publication_published_in && content.publication_published_in}

        </p>
        <p>
            <h3>Year:</h3>
            {content.publication_year && content.publication_year.title }
        </p>
          {content.publication_funding &&
            <p>
              <h3>Funding:</h3>
              {content.publication_funding}
            </p>
          }

          {Array.isArray(content.publication_related_projects) && content.publication_related_projects.length > 0  &&
            <p>
              <h3>Related Projects:</h3>
              {content.publication_related_projects.map(project => project.title).join(", ")}
            </p>
          }

          {content.publication_doi &&
            <p>
              <h3>DOI:</h3>
              {content.publication_doi}
            </p>
          }

          {
            (content.publication_pre_print_link ||
            content.publication_publication_link ||
            content.publication_paper_website ||
            content.publication_code_repository ||
            content.publication_video_link ||
            content.publication_file_link ||
            content.publication_video_file ||
            content.publication_pdf_file) &&
            <h3>Links:</h3>
          }
          <p>
            {content.publication_pdf_file &&
              <>
                {/* <h3>Local PDF File:</h3> */}
                <a href={content.publication_pdf_file + '/@@display-file/file' }  className={"clickable-box"}>PDF File</a>
              </>
            }
            {content.publication_pre_print_link &&
              <>
                {/* <h3>Pre-Print Link:</h3> */}
                <a href={content.publication_pre_print_link} className={"clickable-box"}>Preprint</a>
              </>
            }

            {content.publication_publication_link &&
              <>
                {/* <h3>Publication Link:</h3> */}
                <a href={content.publication_publication_link} className={"clickable-box"}>Publication</a>
              </>
            }

            {content.publication_video_link &&
              <>
                {/* <h3>Video Link:</h3> */}
                <a href={content.publication_video_link} className={"clickable-box"}>Video</a>
              </>
            }

            {content.publication_paper_website &&
              <>
                {/* <h3>Paper Website:</h3> */}
                <a href={content.publication_paper_website} className={"clickable-box"}>Paper Website</a>
              </>
            }

            {content.publication_code_repository &&
              <>
                {/* <h3>Code Repository:</h3> */}
                <a href={content.publication_code_repository} className={"clickable-box"}>Code</a>
              </>
            }

            {content.publication_dataset_link &&
              <>
                {/* <h3>Code Repository:</h3> */}
                <a href={content.publication_dataset_link} className={"clickable-box"}>Dataset</a>
              </>
            }

            {content.publication_file_link &&
              <>
                {/* <h3>File Link:</h3> */}
                <a href={content.publication_file_link}  className={"clickable-box"}>PDF</a>
              </>
            }

            {content.publication_video_file &&
              <>
                {/* <h3>Local Video File:</h3> */}
                <a href={content.publication_video_file} className={"clickable-box"}>Video File</a>
              </>
            }

          </p>

            {content.publication_bibtex_string && content.publication_bibtex_string?.data !="<p></p>" && (
              <>
                <h3>BibTex String</h3>
                <pre className={"bibtex-box"} dangerouslySetInnerHTML={{ __html: content.publication_bibtex_string.data.replace(/<p>/g, '').replace(/<\/p>/g, '<br />') }} />
              </>
            )}

        </span>

        </Grid.Column>
        <Grid.Column>
          <Grid>
            <Grid.Row className={"image-row"}>
              <div className="images-container-pub">
                {(content.publication_teaser_image1 &&
                  content.publication_teaser_image2) &&
                  <>
                    {content.publication_teaser_image1 && (
                    <img
                      src={flattenToAppURL(content.publication_teaser_image1.scales.preview.download)}
                      alt="Topic"
                      className="publication-image"
                    />
                  )}
                    {content.publication_teaser_image2 && (
                      <img
                        src={flattenToAppURL(content.publication_teaser_image2.scales.preview.download)}
                        alt="Topic"
                        className="publication-image"
                      />
                    )}
                  </>
                }
                {(content.publication_teaser_image1 &&
                    !content.publication_teaser_image2) &&
                  <>
                    {content.publication_teaser_image1 && (
                      <img
                        src={flattenToAppURL(content.publication_teaser_image1.scales.preview.download)}
                        alt="Topic"
                        className="img-single publication-image"
                      />
                    )}
                  </>
                }

              </div>


            </Grid.Row>

            <Grid.Row>


          <Segment className='PublicationAbstract'>
            <h3>Abstract:</h3>
            {content.publication_abstract.data && <div dangerouslySetInnerHTML={{ __html: content.publication_abstract.data.replace(/<p>/g, '').replace(/<\/p>/g, '') }} />
            }
          </Segment>

          </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DefaultView {...props} />
    </Container>
  );
};

export default PublicationView;
