import { useEffect, useState } from 'react';

// Annahme: BASE_API_PATH ist der Basispfad Ihrer API,
// z.B. "/++api++" oder ein anderer Pfad, unter dem Ihre API erreichbar ist.
// Diese Variable sollte entsprechend Ihrer API-Konfiguration angepasst werden.

const BASE_API_PATH = '/++api++';

const useFetchItemDetails = (relativeUrl) => {
    
    const [itemDetails, setItemDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Sicherstellen, dass eine relative URL bereitgestellt wurde
        if (!relativeUrl) {
            console.warn('useFetchItemDetails was called without a relative URL.');
            return;
        }

        // Konstruieren der vollständigen API-URL unter Verwendung des relativen Pfades
        const apiUrl = `${BASE_API_PATH}${relativeUrl}`;

        const fetchItemDetails = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) throw new Error(`Response not ok. Status: ${response.status}`);
                const data = await response.json();
                setItemDetails(data);
            } catch (error) {
                console.error('Error fetching item details:', error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchItemDetails();
    }, [relativeUrl]);

    return { itemDetails, isLoading, error };
};

export default useFetchItemDetails;

