/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */

import { defineMessages, useIntl } from 'react-intl';
import { Image } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { toBackendLang } from '@plone/volto/helpers';
/** import LogoImage from '@plone/volto/components/theme/Logo/Logo.svg';
 */
import LogoImage from './logo_hrl_res.png';
import LogoImageUni from './logo_uni_short_res.png';
import './Logo.css'; // Importing a CSS file to style the logos

const messages = defineMessages({
  uni: {
    id: 'Uni-Bonn',
    defaultMessage: 'Uni-Bonn',
  },
  hrl: {
    id: 'Humanoid Robots Lab',
    defaultMessage: 'Humanoid Robots Lab',
  },
});

/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const Logo = () => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const intl = useIntl();

  return (
    <div className="logo-container">
      <UniversalLink
        href={'https://www.uni-bonn.de/de'}
        title={'Website Uni Bonn'}
      >
        <Image
          className="logo"
          src={LogoImageUni}
          alt={intl.formatMessage(messages.uni)}
          title={intl.formatMessage(messages.uni)}
        />
      </UniversalLink>
      <UniversalLink
        href={settings.isMultilingual ? `/${toBackendLang(lang)}` : '/'}
        title={intl.formatMessage(messages.hrl)}
      >
        <Image
          className="logo"
          src={LogoImage}
          alt={intl.formatMessage(messages.hrl)}
          title={intl.formatMessage(messages.hrl)}
        />
      </UniversalLink>
    </div>
  );
};

export default Logo;
