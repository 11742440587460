import React from 'react';
import PropTypes from 'prop-types';
import { DefaultView } from '@plone/volto/components';
import { Card, Container, Segment, Image, List, Grid } from 'semantic-ui-react';
import {
  UniversalLink,
  ConditionalLink,
  Component,
} from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

import './peopleListingTemplate.css';

const peopleListingTemplate = ({ items }) => {
  return (
    <>
      <Container className="view-wrapper">
        <Card.Group>
          {items &&
            items.map((item) => (
              <Card color="grey" className="Card PeopleCard" key={item['@id']}>
                <Grid celled className="PeopleGrid" columns={2}>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      {item.type_of_position === 'Student Assistant' ||
                      item.type_of_position === 'Alumni' ? (
                        <Image
                          className="PeopleImage"
                          src={
                            item.image_scales?.image?.[0]?.scales?.mini
                              ?.download
                              ? item['@id'] +
                                '/' +
                                flattenToAppURL(
                                  item.image_scales.image[0].scales.mini
                                    .download,
                                )
                              : '/people_default_image.png'
                          }
                          size="small"
                          floated="left"
                          alt={item.image_caption}
                        />
                      ) : (
                        <UniversalLink href={item['@id']} title={item['@type']}>
                          <Image
                            className="PeopleImage"
                            src={
                              item.image_scales?.image?.[0]?.scales?.mini
                                ?.download
                                ? item['@id'] +
                                  '/' +
                                  flattenToAppURL(
                                    item.image_scales.image[0].scales.mini
                                      .download,
                                  )
                                : '/people_default_image.png'
                            }
                            size="small"
                            floated="left"
                            alt={item.image_caption}
                          />
                        </UniversalLink>
                      )}
                    </Grid.Column>

                    <Grid.Column width={7} className="PeopleContent">
                      <h4 className="PeoplePerson">
                        {item.type_of_degree && (
                          <div className="PeopleDegree">
                            {item.type_of_degree}
                          </div>
                        )}

                        {item.type_of_position === 'Student Assistant' ||
                        item.type_of_position === 'Alumni' ? (
                          <h4 className="PeopleName">
                            {item.full_name ? item.full_name : item.id}
                          </h4>
                        ) : (
                          <UniversalLink
                            href={item['@id']}
                            title={item['@type']}
                          >
                            <h4 className="PeopleName">
                              {item.full_name ? item.full_name : item.id}
                            </h4>
                          </UniversalLink>
                        )}

                        {item.type_of_position && (
                          <div className="PeoplePosition font_weight_300 ">
                            {item.type_of_position}
                          </div>
                        )}
                      </h4>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card>
            ))}
        </Card.Group>
      </Container>
    </>
  );
};

peopleListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default peopleListingTemplate;
