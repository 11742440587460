import React from 'react';
import { DefaultView } from '@plone/volto/components';
import { UniversalLink, ConditionalLink, Link } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Container, Grid, Divider, Card, Header, Image, Label, List, Segment } from 'semantic-ui-react';

import moment from "moment";

import './Thesis.css';

const ThesisView = (props) => {
  const { content } = props;

  return (
    <Container id="view-wrapper" className="JobView">
      <Grid>
        <Grid.Row>
          <Grid.Column>
           <span className="JobInfo">

            {content.title && (
                <>
                    <h2> {content.title}</h2>
                </>
            )}


            {content.thesis_teaser_image && (
              <div className="thesis-images-container">
                  <img
                    src={flattenToAppURL(content.thesis_teaser_image.scales.preview.download)}
                    alt="Thesis"
                    className="thesis-image"
                  />
                </div>
            )}

            {content.thesis_degree  && (
              <>
                <h3>Thesis Degree:</h3>
                  {content.thesis_degree && content.thesis_degree.join(', ')}
              </>
            )}


            {content.thesis_advisors && (
              <>
                <h3> Thesis Advisors: </h3>
                  {content.thesis_advisors.map((item) => (
                    <span key={item['@id']}>
                      <UniversalLink href={item['@id']} title={item['@type']}>
                        {item.full_name }
                          <br></br>
                      </UniversalLink>
                    </span>
                  ))}
                </>
              )}

              {content.thesis_description && content.thesis_description?.data !="<p></p>" && (
                <>
                  <h3> Thesis Description: </h3>
                    <div dangerouslySetInnerHTML={{ __html: content.thesis_description.data }} />
                </>
              )}

              {content.thesis_abstract && content.thesis_abstract?.data !="<p></p>" && (
                <>
                  <h3> Thesis Abstract: </h3>
                    <div dangerouslySetInnerHTML={{ __html: content.thesis_abstract.data }} />
                </>
              )}

              {content.thesis_start_date && (
                <>
                  <h3> Thesis Start Date: </h3>
                    {moment(content.thesis_start_date).format("MMMM D, YYYY")}
                </>
              )}

              {content.thesis_requirements && content.thesis_requirements?.data !="<p></p>" && (
                <>
                  <h3> Thesis Requirements: </h3>
                    <div dangerouslySetInnerHTML={{ __html: content.thesis_requirements.data }} />
                </>
              )}

              {content.thesis_related_work && content.thesis_related_work?.data !="<p></p>" && (
                <>
                  <h3> Thesis Related Work: </h3>
                    <div dangerouslySetInnerHTML={{ __html: content.thesis_related_work.data }} />
                </>
              )}

            </span>

           </Grid.Column>

        </Grid.Row>

      </Grid>

  </Container>
  );
};
export default ThesisView;

