/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

const Footer = ({ intl }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );
  return (
    <Segment id="footer">

        <div className="company-footer">
          <Container>

          <div className="footer-category-links">

            <div className="category-content">

              <div className="address">
                <h3>Contact: </h3>
                <p>
                  Prof. Dr. Maren Bennewitz<br></br>
                  Humanoid Robots Lab<br></br>
                  University of Bonn<br></br>
                  Friedrich-Hirzebruch-Allee 8<br></br>
                  53115 Bonn
                </p>
       		      <p>
                  Tel.:  +49 (0) 228 73-54164<br></br>
		              Secr.: +49 (0) 228 73-4422<br></br>
                  Fax.:  +49 (0) 228 73-4425<br></br>
                  Email: maren_at_cs.uni-bonn.de
                </p>
                <p>
                  Office: 2.022
                </p>
              </div>

            </div>

          </div>
        </Container>

          <div className="upper-footer">
          </div>

          <div className="lower-footer">
            <Container>

              <div className="company-name">
                <a href="https://www.uni-bonn.de">University of Bonn</a>
              </div>

              <div className='institute-name'>
                <a href="http://www.informatik.uni-bonn.de/">Institute for Computer Science</a>
              </div>

              <div className='institute-name'>
                <a href="https://www.youtube.com/@HumanoidsBonn">Youtube</a>
              </div>

              <div className="site-actions">
                <a href="https://www.uni-bonn.de/en/imprint">Imprint</a>
                <a href="https://www.uni-bonn.de/en/data-protection-policy">Data Protection Policy</a>
              </div>
            </Container>
          </div>

      </div>
</Segment>

  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
