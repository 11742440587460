/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

import icon from '@plone/volto/icons/list-bullet.svg';

import {  TalkView, 
          PublicationView,
          PeopleView,
          TopicView,
          JobView,
          CourseView,
          ProjectView,
          ThesisView } from './components';

import {  peopleListingTemplate, 
          newsListingTemplate, 
          newsListing2Template, 
          publicationListingTemplate, 
          jobListingTemplate, 
          topicListingTemplate,
          projectListingTemplate,
          courseListingTemplate,
          titleListingTemplate,
          thesisListingTemplate } from './components';

          ThesisView

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {


  config.blocks.requiredBlocks = [];
  
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
    matomoSiteId: '146',
    matomoUrlBase: 'https://webstat.hrz.uni-bonn.de/',
  };
  
   config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      people: PeopleView,
      talk: TalkView,
      publication: PublicationView,
      topic: TopicView,
      job: JobView,
      course: CourseView,
      project: ProjectView,
      thesis: ThesisView,
    },
  };
  
  config.blocks.blocksConfig.listing.variations.push({
    id: "peopleListing",
    title: "People Listing",
    template: peopleListingTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "newsListing",
    title: "News Listing",
    template: newsListingTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "newsListing2",
    title: "News Listing 2",
    template: newsListing2Template,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "publicationsListing",
    title: "Publications Listing",
    template: publicationListingTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "jobsListing",
    title: "Jobs Listing",
    template: jobListingTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "topicsListing",
    title: "Topics Listing",
    template: topicListingTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "projectsListing",
    title: "Projects Listing",
    template: projectListingTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "coursesListing",
    title: "Courses Listing",
    template: courseListingTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "titleListing",
    title: "Only Title Listing",
    template: titleListingTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: "thesisListing",
    title: "Thesis Listing",
    template: thesisListingTemplate,
  });

 
  return config;
}

