import React from 'react';
import { DefaultView } from '@plone/volto/components';
import { UniversalLink, ConditionalLink, Link } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  Container,
  Grid,
  Divider,
  Card,
  Header,
  Image,
  Label,
  List,
  Segment,
} from 'semantic-ui-react';

import moment from 'moment';

import './Job.css';

const JobView = (props) => {
  const { content } = props;

  return (
    <Container id="view-wrapper" className="JobView">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <span className="JobInfo">
              {content.job_position_type && (
                <>
                  <h3> Job Position Type: </h3>
                  {content.job_position_type.title}
                  <br></br>
                </>
              )}

              {content.job_description &&
                content.job_description?.data != '<p></p>' && (
                  <>
                    <h3> Job Description: </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.job_description.data,
                      }}
                    />
                  </>
                )}

              {content.job_start_date && (
                <>
                  <h3> Job Start Date: </h3>
                  {moment(content.job_start_date).format('MMMM D, YYYY')}
                </>
              )}

              {content.job_application_end_date && (
                <>
                  <h3> Job Application End Date: </h3>
                  {moment(content.job_application_end_date).format(
                    'MMMM D, YYYY',
                  )}
                </>
              )}

              {content.job_week_hours && (
                <>
                  <h3> Job Week Hours: </h3>
                  {content.job_week_hours}
                </>
              )}

              {content.job_payment && (
                <>
                  <h3> Job Payment: </h3>
                  {content.job_payment}
                </>
              )}

              {(content.job_contact?.length ?? 0) > 0 && (
                <>
                  <h3> Job Contact: </h3>
                  {content.job_contact.map((item) => (
                    <span key={item['@id']}>
                      <UniversalLink href={item['@id']} title={item['@type']}>
                        {item.full_name}
                        <br></br>
                      </UniversalLink>
                    </span>
                  ))}
                </>
              )}

              {content.job_pdf_file && (
                <>
                  <h3>
                    <a href={content.job_pdf_file} download>
                      Download Job PDF
                    </a>
                  </h3>
                </>
              )}

              {content.job_linkedin_link && (
                <>
                  <h3>
                    <a href={content.job_linkedin_link}> LinkedIn</a>
                  </h3>
                </>
              )}

              {content.job_additional_link && (
                <>
                  <h3>
                    <a href={content.job_additional_link}> Additional Link</a>
                  </h3>
                </>
              )}
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DefaultView {...props} />
    </Container>
  );
};
export default JobView;
