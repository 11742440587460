import React from 'react';
import { UniversalLink, ConditionalLink } from '@plone/volto/components';
import { DefaultView } from '@plone/volto/components';
import { Card, Container, Segment, Image, List, Grid } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import moment from 'moment';

import './publicationsListingTemplate.css';

const publicationListingTemplate = ({ items }) => {
  return (
    <>
      <Container className="view-wrapper">
        <article>
          <section>
            <Card.Group>
              {items &&
                items.map((item) => (
                  <Card
                    fluid
                    color="grey"
                    className="Card PublicationsCard"
                    key={item['@id']}
                  >
                    <Grid className="PublicationGrid" stackable columns={2}>
                      <Grid.Column className="PublicationImageColumn" width={3}>
                        <Segment className="SegmentInvisible">
                          {item.image_scales &&
                            item.image_scales.publication_teaser_image1 && (
                              <Image
                                className="PublicationsImage"
                                src={
                                  item.image_scales.publication_teaser_image1[0]
                                    .scales.preview
                                    ? item['@id'] +
                                      '/' +
                                      flattenToAppURL(
                                        item.image_scales
                                          .publication_teaser_image1[0].scales
                                          .preview.download,
                                      )
                                    : item['@id'] +
                                      '/' +
                                      flattenToAppURL(
                                        item.image_scales
                                          .publication_teaser_image1[0].scales
                                          .mini.download,
                                      )
                                }
                                size="mini"
                                floated="left"
                                alt={item.image_caption}
                              />
                            )}
                        </Segment>
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        className="PublicationContentColumn PublicationContent"
                      >
                        <Segment className="SegmentInvisible">
                          <UniversalLink
                            href={item['@id']}
                            title={item['@type']}
                          >
                            <h3 className="PublicationsTitle">
                              {item.title ? item.title : item.id}
                            </h3>
                          </UniversalLink>

                          <p className="PublicationsInfo">
                            <span className="PublicationsInfo">
                              {item.publication_authors &&
                                item.publication_authors.join(', ')}{' '}
                              <br></br>
                              {item.publication_published_in},{' '}
                              {item.publication_year} <br></br>
                              <a
                                href={item['@id']}
                                title={item['@type']}
                                className={'clickable-box-listing'}
                              >
                                More info
                              </a>
                              {item.publication_pdf_file && (
                                <>
                                  {/* <h3>Local PDF File:</h3> */}
                                  <a
                                    href={
                                      item.publication_pdf_file +
                                      '/@@display-file/file'
                                    }
                                    className={'clickable-box-listing'}
                                  >
                                    PDF File
                                  </a>
                                </>
                              )}
                              {/*
                                                  {item.publication_publication_link &&
                                                    <>
                                                      <a href={item.publication_publication_link} className={"clickable-box-listing"}>Publication</a>
                                                    </>
                                                  }
                                                */}
                              {(item.publication_pre_print_link ||
                                item.publication_publication_link
                                ) && (
                                <a
                                  href={
                                    item.publication_pre_print_link ||
                                    item.publication_publication_link
                                  }
                                  className={'clickable-box-listing'}
                                >
                                  Paper
                                </a>
                              )}
                              {/*
                                                {item.publication_pre_print_link &&
                                                  <>
                                                    <a href={item.publication_pre_print_link} className={"clickable-box-listing"}>Preprint</a>
                                                  </>
                                                }
                                              */}
                              {item.publication_video_link && (
                                <>
                                  {/* <h3>Video Link:</h3> */}
                                  <a
                                    href={item.publication_video_link}
                                    className={'clickable-box-listing'}
                                  >
                                    Video
                                  </a>
                                </>
                              )}
                              {item.publication_paper_website && (
                                <>
                                  {/* <h3>Paper Website:</h3> */}
                                  <a
                                    href={item.publication_paper_website}
                                    className={'clickable-box-listing'}
                                  >
                                    Paper Website
                                  </a>
                                </>
                              )}
                              {item.publication_code_repository && (
                                <>
                                  {/* <h3>Code Repository:</h3> */}
                                  <a
                                    href={item.publication_code_repository}
                                    className={'clickable-box-listing'}
                                  >
                                    Code
                                  </a>
                                </>
                              )}
                              {item.publication_dataset_link && (
                                <>
                                  {/* <h3>Code Repository:</h3> */}
                                  <a
                                    href={item.publication_dataset_link}
                                    className={'clickable-box-listing'}
                                  >
                                    Dataset
                                  </a>
                                </>
                              )}
                              {item.publication_file_link && (
                                <>
                                  {/* <h3>File Link:</h3> */}
                                  <a
                                    href={item.publication_file_link}
                                    className={'clickable-box-listing'}
                                  >
                                    PDF
                                  </a>
                                </>
                              )}
                              {item.publication_video_file && (
                                <>
                                  {/* <h3>Local Video File:</h3> */}
                                  <a
                                    href={item.publication_video_file}
                                    className={'clickable-box-listing'}
                                  >
                                    Video File
                                  </a>
                                </>
                              )}
                            </span>
                          </p>
                        </Segment>
                      </Grid.Column>
                    </Grid>
                  </Card>
                ))}
            </Card.Group>
          </section>
        </article>
      </Container>
    </>
  );
};

export default publicationListingTemplate;
