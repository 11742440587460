import React from 'react';
import PropTypes from 'prop-types';
import { DefaultView } from '@plone/volto/components';
import {
  UniversalLink,
  ConditionalLink,
  Component,
} from '@plone/volto/components';
import { Card, Container, Segment, Image, List, Grid } from 'semantic-ui-react';

import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import moment from 'moment';

import './courseListingTemplate.css';

const courseListingTemplate = ({ items }) => {
  return (
    <>
      <Container className="view-wrapper">
        <Card.Group>
          {items &&
            items.map((item) => (
              <Card
                fluid
                color="grey"
                className="Card CourseCard"
                key={item['@id']}
              >
                <Grid className="PublicationGrid" stackable columns={2}>
                  <Grid.Column className="PublicationImageColumn" width={3}>
                    <Segment className="SegmentInvisible">
                      <UniversalLink href={item['@id']} title={item['@type']}>
                        <Image
                          className="PublicationsImage"
                          src={
                            item.image_scales?.image?.[0]?.scales?.preview
                              ?.download
                              ? item['@id'] +
                                '/' +
                                flattenToAppURL(
                                  item.image_scales.image[0].scales.preview
                                    .download,
                                )
                              : '/course_default_image.png'
                          }
                          size="mini"
                          floated="left"
                          alt={item.image_caption}
                        />
                      </UniversalLink>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column
                    width={8}
                    className="PublicationContentColumn PublicationContent"
                  >
                    <UniversalLink href={item['@id']} title={item['@type']}>
                      <h3>{item.type_of_course && item.type_of_course}</h3>
                      <h2>{item.title ? item.title : item.id}</h2>
                    </UniversalLink>

                    <p className="PublicationsInfo">
                      <span className="PublicationsInfo">
                        Course number:{' '}
                        {item.course_number && item.course_number}
                      </span>
                    </p>
                  </Grid.Column>
                </Grid>
              </Card>
            ))}
        </Card.Group>
      </Container>
    </>
  );
};

courseListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default courseListingTemplate;
