import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import { Card, Container, Image, Grid, Segment } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';

const thesisListingTemplate = ({ items }) => {
  return (
    <>
      <Container className="view-wrapper">
        <Card.Group>
          {items &&
            items.map((item) => (
              <Card
                fluid
                color="grey"
                className="Card HRLCard"
                key={item['@id']}
              >
                <Grid className="PublicationGrid" stackable columns={2}>
                  <Grid.Column className="PublicationImageColumn" width={3}>
                    <Segment className="SegmentInvisible">
                      <UniversalLink href={item['@id']} title={item['@type']}>
                        <Image
                          className="PublicationsImage"
                          src={
                            item.image_scales?.thesis_teaser_image?.[0]?.scales
                              ?.preview?.download
                              ? item['@id'] +
                                '/' +
                                flattenToAppURL(
                                  item.image_scales.thesis_teaser_image[0]
                                    .scales.preview.download,
                                )
                              : '/thesis_default_image.png'
                          }
                          size="mini"
                          floated="left"
                          alt={item.image_caption}
                        />
                      </UniversalLink>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column
                    width={8}
                    className="PublicationContentColumn PublicationContent"
                  >
                    <UniversalLink href={item['@id']} title={item['@type']}>
                      <h3 className="PublicationsTitle">
                        {item.title ? item.title : item.id}
                      </h3>
                    </UniversalLink>
                    <p>{item.description && item.description}</p>
                  </Grid.Column>
                </Grid>
              </Card>
            ))}
        </Card.Group>
      </Container>
      <br></br>
    </>
  );
};

thesisListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default thesisListingTemplate;
