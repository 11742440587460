import React from 'react';
import { DefaultView } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  Container,
  Segment,
  Header,
  Icon,
  Divider,
  Grid,
  Label,
  Image,
  List,
} from 'semantic-ui-react';
import RichTextRenderer from 'react-rich-text';

import PropTypes from 'prop-types';

import './People.css';
import './academicons/css/academicons.min.css';
import './icomoon/style.css';

function isEmptyHtml(content) {
  // Remove all HTML tags
  const strippedContent = content.replace(/<[^>]*>/g, '');

  // Trim whitespace and check if the remaining content is empty
  return strippedContent.trim() === '';
}

const PeopleView = (props) => {
  const { content } = props;

  if (content.address) {
    const addressWithLineBreaks = content.address.replace(/\n/g, '<br>');
  }

  return (
    <Container id="view-wrapper" className="PeopleView">
      {content.type_of_degree && (
        <h3 className="PeopleDegree">{content.type_of_degree.title}</h3>
      )}

      <h2 className="PeopleName">
        {content.full_name ? content.full_name : content.full_name}
      </h2>
      <h3 className="PeoplePosition">
        {content.type_of_position.title
          ? content.type_of_position.title
          : content.type_of_position.token}{' '}
        <br></br>
      </h3>

      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column width={3} className="PeopleImageGrid">
            {content.image && (
              <Image
                src={
                  content.image
                    ? content['@id'] +
                      '/' +
                      flattenToAppURL(content.image.scales.large.download)
                    : '/people_default_image.png'
                }
                size="medium"
                floated="left"
                alt={content.image_caption}
              />
            )}
          </Grid.Column>
          <Grid.Column width={9}>
            <Grid columns={2} className="PeopleContactGrid">
              {content.research_areas &&
                content.research_areas?.data != '<p></p>' && (
                  <>
                    <h3 className={'h3_nomargin'}>Research Interest:</h3>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <div
                          className={'research_interest'}
                          dangerouslySetInnerHTML={{
                            __html: content.research_areas.data,
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              {(content.office ||
                content.telephone ||
                content.address ||
                content.email ||
                content.fax) && (
                <>
                  <h3>Contact:</h3>
                  {content.office && (
                    <Grid.Row>
                      <Grid.Column className={'column-1'}>Room:</Grid.Column>
                      <Grid.Column className={'column-11'}>
                        {content.office}
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {content.telephone && (
                    <Grid.Row>
                      <Grid.Column className={'column-1'}>Tel.:</Grid.Column>
                      <Grid.Column className={'column-11'}>
                        {content.telephone}
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {content.address && (
                    <Grid.Row>
                      <Grid.Column className={'column-1'}>Address:</Grid.Column>
                      <Grid.Column className={'column-11, PeopleAddress'}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: addressWithLineBreaks,
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {content.email && (
                    <Grid.Row>
                      <Grid.Column className={'column-1'}>Email:</Grid.Column>
                      <Grid.Column className={'column-11'}>
                        {content.email}
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {content.fax && (
                    <Grid.Row>
                      <Grid.Column className={'column-1'}>Fax:</Grid.Column>
                      <Grid.Column className={'column-11'}>
                        {content.fax}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </>
              )}

              {(content.website ||
                content.linkedin ||
                content.github ||
                content.google_scholar ||
                content.orcid_id ||
                content.twitter) && (
                <Grid.Row>
                  <Grid.Column className={'column-1'}>Links:</Grid.Column>
                  <Grid.Column className={'column-11'}>
                    <>
                      {content.website && (
                        <>
                          <a href={content.website}>
                            <span className="icon-home ic-link"></span>
                          </a>
                        </>
                      )}
                      {content.twitter && (
                        <>
                          <a href={content.twitter}>
                            <span className="icon-twitter ic-link"></span>
                          </a>
                        </>
                      )}
                      {content.linkedin && (
                        <>
                          <a href={content.linkedin}>
                            <span className="icon-linkedin ic-link"></span>
                          </a>
                        </>
                      )}
                      {content.github && (
                        <>
                          <a href={content.github}>
                            <span className="icon-github ic-link"></span>
                          </a>
                        </>
                      )}
                      {content.google_scholar && (
                        <>
                          <a href={content.google_scholar}>
                            <i className="ai ai-google-scholar ai-3x ic-link-larger"></i>
                          </a>
                        </>
                      )}
                      {content.orcid_id && (
                        <>
                          <a href={content.orcid_id}>
                            <i className="ai ai-orcid ai-3x ic-link-larger"></i>
                          </a>
                        </>
                      )}
                      {content.researchgate && (
                        <>
                          <a href={content.researchgate}>
                            <i className="ai ai-researchgate ai-3x ic-link-larger"></i>
                          </a>
                        </>
                      )}
                    </>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      {content.about && content.about?.data != '<p></p>' && (
        <>
          <h3>About me:</h3>
          <div dangerouslySetInnerHTML={{ __html: content.about.data }}></div>
        </>
      )}

      <br />

      <DefaultView {...props} />
    </Container>
  );
};

export default PeopleView;
